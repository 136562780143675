import { createContext, ReactNode, useContext } from 'react';
import { TBaptismContext } from '../../types/Baptisms/BaptismContext';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { BAPTISM_DATES_API, BAPTISMS_API } from '../../utils/constants';

const BaptismContext = createContext<TBaptismContext | null>(null);

type Props = {
   children: ReactNode;
};

export function BaptismProvider({ children }: Props) {
   const axiosPrivate = useAxiosPrivate();

   const addBaptismDate: TBaptismContext['addBaptismDate'] = data =>
      axiosPrivate.post(BAPTISM_DATES_API, data).then(res => res.data);

   const getBaptismDates: TBaptismContext['getBaptismDates'] = ({
      type,
   } = {}) =>
      axiosPrivate
         .get(BAPTISM_DATES_API, { params: { type } })
         .then(res => res.data);

   const getBaptismDate: TBaptismContext['getBaptismDate'] = id =>
      axiosPrivate.get(`${BAPTISM_DATES_API}/${id}`).then(res => res.data);

   const updateBaptismDate: TBaptismContext['updateBaptismDate'] = ([
      data,
      id,
   ]) =>
      axiosPrivate
         .patch(`${BAPTISM_DATES_API}/${id}`, data)
         .then(res => res.data);

   const getUpcomingDatesWithBaptisms = () =>
      axiosPrivate
         .get(`${BAPTISM_DATES_API}/upcomingDatesWithBaptisms`)
         .then(res => res.data);

   const getBaptismsOfPastDates: TBaptismContext['getBaptismsOfPastDates'] = ({
      pageParam = 1,
   }) =>
      axiosPrivate
         .get(`${BAPTISM_DATES_API}/baptismsOfPastDates`, {
            params: { page: pageParam },
         })
         .then(res => res.data);

   const addBaptism: TBaptismContext['addBaptism'] = formData =>
      axiosPrivate
         .post(BAPTISMS_API, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
         })
         .then(res => res.data);

   const getBaptism: TBaptismContext['getBaptism'] = id =>
      axiosPrivate.get(`${BAPTISMS_API}/${id}`).then(res => res.data);

   const updateBaptism: TBaptismContext['updateBaptism'] = ([formData, id]) =>
      axiosPrivate
         .patch(`${BAPTISMS_API}/${id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
         })
         .then(res => res.data);

   const deleteBaptism: TBaptismContext['deleteBaptism'] = id =>
      axiosPrivate.delete(`${BAPTISMS_API}/${id}`).then(res => res.data);

   return (
      <BaptismContext.Provider
         value={{
            addBaptismDate,
            getBaptismDates,
            getBaptismDate,
            updateBaptismDate,
            getUpcomingDatesWithBaptisms,
            getBaptismsOfPastDates,
            addBaptism,
            updateBaptism,
            getBaptism,
            deleteBaptism,
         }}
      >
         {children}
      </BaptismContext.Provider>
   );
}

export default function useBaptismContext() {
   const context = useContext(BaptismContext);

   if (!context)
      throw new Error('Baptism Context must be used in a Baptism Provider.');
   return context;
}
