import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useBaptismContext from '../../../context/Baptisms/BaptismContext';
import useInput from '../../../hooks/useInput';
import { IBaptism, INewBaptism } from '../../../types/Baptisms/Baptisms';
import { dateObjToInputVal } from '../../../utils/dateFunctions';
import { FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TBaptismDate } from '../../../types/Baptisms/BaptismDates';
import ConfirmBox from '../../UI/ConfirmBox';
import { objectToFormdata } from '../../../utils/utilities';
import useFileInput from '../../../hooks/useFileInput';
import FileInput from '../../FileInput';

type Props = { isLoading?: boolean } & (
   | {
        baptismDoc?: null;
        submitFn: (data: FormData) => any;
     }
   | {
        baptismDoc: IBaptism;
        submitFn: ([data, id]: [FormData, string]) => any;
     }
);

export default function BaptismForm({
   submitFn,
   baptismDoc,
   isLoading,
}: Props) {
   const { getBaptismDates, getBaptismDate, deleteBaptism } =
      useBaptismContext();
   const navigate = useNavigate();
   const queryClient = useQueryClient();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   const { data: baptismDates, isLoading: isDatesLoading } = useQuery({
      queryFn: () => getBaptismDates({ type: 'future' }),
      queryKey: ['baptismDates', { type: 'future' }],
   });

   const [shouldFetchPastDate, setShouldFetchPastDate] = useState(false);
   const [dateTime, dateTimeAttr] = useInput(
      typeof baptismDoc?.dateTime === 'object'
         ? baptismDoc?.dateTime?._id
         : typeof baptismDoc?.dateTime === 'string'
         ? baptismDoc?.dateTime
         : ''
   );

   const pastBaptismDateQuery = useQuery({
      queryFn: () => getBaptismDate(dateTime!),
      queryKey: ['baptismDates', {}, dateTime],
      enabled: shouldFetchPastDate,
      placeholderData: null,
   });

   useEffect(() => {
      if (
         (Array.isArray(baptismDates) && baptismDates?.length > 0
            ? baptismDates
            : []
         )?.some(date => date._id === dateTime)
      ) {
         return;
      } else if (Array.isArray(baptismDates) && baptismDates?.length > 0) {
         setShouldFetchPastDate(true);
      }
   }, [baptismDates, dateTime]);

   const [firstname, firstnameAttr] = useInput(baptismDoc?.firstname ?? '');
   const [middlename, middleNameAttr] = useInput(baptismDoc?.middlename ?? '');
   const [lastname, lastnameAttr] = useInput(baptismDoc?.lastname ?? '');
   const [dateOfBirth, dobAttr] = useInput(
      baptismDoc?.dateOfBirth ? dateObjToInputVal(baptismDoc.dateOfBirth) : ''
   );
   const [motherFirstname, mFirstnameAttr] = useInput(
      baptismDoc?.mother?.firstname ?? ''
   );
   const [motherMiddlename, mMiddlenameAttr] = useInput(
      baptismDoc?.mother?.middlename ?? ''
   );
   const [motherLastname, mLastnameAttr] = useInput(
      baptismDoc?.mother?.lastname ?? ''
   );
   const [fatherFirstname, fFirstnameAttr] = useInput(
      baptismDoc?.father?.firstname ?? ''
   );
   const [fatherMiddlename, fMiddlenameAttr] = useInput(
      baptismDoc?.father?.middlename ?? ''
   );
   const [fatherLastname, fLastnameAttr] = useInput(
      baptismDoc?.father?.lastname ?? ''
   );
   const [godparent1Firstname, g1FirstnameAttr] = useInput(
      baptismDoc?.godParent1?.firstname ?? ''
   );
   const [godparent1Middlename, g1MiddlenameAttr] = useInput(
      baptismDoc?.godParent1?.middlename ?? ''
   );
   const [godparent1Lastname, g1LastnameAttr] = useInput(
      baptismDoc?.godParent1?.lastname ?? ''
   );
   const [godparent2Firstname, g2FirstnameAttr] = useInput(
      baptismDoc?.godParent2?.firstname ?? ''
   );
   const [godparent2Middlename, g2MiddlenameAttr] = useInput(
      baptismDoc?.godParent2?.middlename ?? ''
   );
   const [godparent2Lastname, g2LastnameAttr] = useInput(
      baptismDoc?.godParent2?.lastname ?? ''
   );
   const [witnessFirstname, wFnameAttr] = useInput(
      baptismDoc?.witness?.firstname ?? ''
   );
   const [witnessMiddlename, wMnameAttr] = useInput(
      baptismDoc?.witness?.middlename ?? ''
   );
   const [witnessLastname, wLnameAttr] = useInput(
      baptismDoc?.witness?.lastname ?? ''
   );
   const [birthCertificate, setBirthCertificate] = useState(
      baptismDoc?.birthCertificate
         ? baptismDoc.birthCertificate === true
         : false
   );
   const [allParentDocs, setAllParentDocs] = useState(
      baptismDoc?.allParentDocs ? baptismDoc.allParentDocs === true : false
   );
   const [godmotherDocs, setGodmotherDocs] = useState(
      baptismDoc?.godmotherDocs ? baptismDoc.godmotherDocs === true : false
   );
   const [godfatherDocs, setGodfatherDocs] = useState(
      baptismDoc?.godfatherDocs ? baptismDoc.godfatherDocs === true : false
   );
   const [baptismClassParents, setBaptismClassParents] = useState(
      baptismDoc?.baptismClassParents
         ? baptismDoc.baptismClassParents === true
         : false
   );
   const [baptismClassGodmother, setBaptismClassGodmother] = useState(
      baptismDoc?.baptismClassGodmother
         ? baptismDoc.baptismClassGodmother === true
         : false
   );
   const [baptismClassGodfather, setBaptismClassGodfather] = useState(
      baptismDoc?.baptismClassGodfather
         ? baptismDoc.baptismClassGodfather === true
         : false
   );
   const [notes, notesAttr] = useInput(baptismDoc?.notes ?? '');

   //Files
   const [birthCertificateFile, birthCertOptions, setBirthCertificateFile] =
      useFileInput(
         baptismDoc?.birthCertificateFile?.src
            ? { name: 'Birth_Certificate.pdf' }
            : null,
         { maxSizeInMB: 30 }
      );

   const [allParentDocsFile, allParentDocsOptions, setAllParentDocsFile] =
      useFileInput(
         baptismDoc?.allParentDocsFile?.src
            ? { name: 'All_Parent_Docs.pdf' }
            : null,
         { maxSizeInMB: 30 }
      );

   const [godmotherDocsFile, godmotherDocsOptions, setGodmotherDocsFile] =
      useFileInput(
         baptismDoc?.godmotherDocsFile?.src
            ? { name: 'Godmother_Docs.pdf' }
            : null,
         { maxSizeInMB: 30 }
      );

   const [godfatherDocsFile, godfatherDocsOptions, setGodfatherDocsFile] =
      useFileInput(
         baptismDoc?.godfatherDocsFile?.src
            ? { name: 'Godfather_Docs.pdf' }
            : null,
         { maxSizeInMB: 30 }
      );

   const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      if (!dateTime) return toast.error('Select a Date/Time');
      if (!firstname) return toast.error('Firstname is required');
      if (!lastname) return toast.error('Lastname is required');
      if (!dateOfBirth) return toast.error('Date of birth is required');

      const data: INewBaptism = {
         dateTime,
         firstname,
         lastname,
         dateOfBirth,
         birthCertificate,
         godmotherDocs,
         godfatherDocs,
         allParentDocs,
         baptismClassParents,
         baptismClassGodmother,
         baptismClassGodfather,
         notes,
      };

      if (middlename) data.middlename = middlename;

      if (motherFirstname || motherMiddlename || motherLastname)
         data.mother = {};
      if (motherFirstname) data.mother!.firstname = motherFirstname;
      if (motherMiddlename) data.mother!.middlename = motherMiddlename;
      if (motherLastname) data.mother!.lastname = motherLastname;

      if (fatherFirstname || fatherMiddlename || fatherLastname)
         data.father = {};
      if (fatherFirstname) data.father!.firstname = fatherFirstname;
      if (fatherMiddlename) data.father!.middlename = fatherMiddlename;
      if (fatherLastname) data.father!.lastname = fatherLastname;

      if (godparent1Firstname || godparent1Middlename || godparent1Lastname)
         data.godParent1 = {};
      if (godparent1Firstname) data.godParent1!.firstname = godparent1Firstname;
      if (godparent1Middlename)
         data.godParent1!.middlename = godparent1Middlename;
      if (godparent1Lastname) data.godParent1!.lastname = godparent1Lastname;

      if (godparent2Firstname || godparent2Middlename || godparent2Lastname)
         data.godParent2 = {};
      if (godparent2Firstname) data.godParent2!.firstname = godparent2Firstname;
      if (godparent2Middlename)
         data.godParent2!.middlename = godparent2Middlename;
      if (godparent2Lastname) data.godParent2!.lastname = godparent2Lastname;

      if (witnessFirstname || witnessMiddlename || witnessLastname)
         data.witness = {};
      if (witnessFirstname) data.witness!.firstname = witnessFirstname;
      if (witnessMiddlename) data.witness!.middlename = witnessMiddlename;
      if (witnessLastname) data.witness!.lastname = witnessLastname;

      const formData = objectToFormdata(data);

      if (birthCertificateFile && 'path' in (birthCertificateFile as File))
         formData.append('birthCertificateFile', birthCertificateFile as File);
      if (allParentDocsFile && 'path' in (allParentDocsFile as File))
         formData.append('allParentDocsFile', allParentDocsFile as File);
      if (godmotherDocsFile && 'path' in (godmotherDocsFile as File))
         formData.append('godmotherDocsFile', godmotherDocsFile as File);
      if (godfatherDocsFile && 'path' in (godfatherDocsFile as File))
         formData.append('godfatherDocsFile', godfatherDocsFile as File);

      if (!baptismDoc) {
         submitFn(formData);
      } else {
         submitFn([formData, baptismDoc._id]);
      }
   };

   const handleCheckChange =
      (setFunction: React.Dispatch<React.SetStateAction<boolean>>) => () => {
         setFunction(prev => !prev);
      };

   //Deleting Baptism
   const [openDeleteModal, setOpenDeleteModal] = useState(false);

   const deleteMutation = useMutation({
      mutationFn: () => deleteBaptism(baptismDoc!._id),
      onSuccess: () => {
         toast.success('Baptism deleted successfully.');
         queryClient.invalidateQueries(['baptisms']);
         queryClient.invalidateQueries(['baptismDates', { type: 'future' }]);
         queryClient.invalidateQueries(['baptismDates', { type: 'past' }]);
         queryClient.invalidateQueries(['upcomingDatesWithBaptisms']);
         queryClient.invalidateQueries(['infinitePastBaptisms']);
         navigate('/baptisms', { replace: true });
      },
   });

   return (
      <>
         <form onSubmit={handleSubmit}>
            <div className='space-y-12 px-10 mb-40 mt-10'>
               <div className='border-b border-gray-900/10 pb-12'>
                  <h2 className='text-base font-semibold leading-7 text-gray-900'>
                     {baptismDoc?._id ? 'Edit Baptism' : 'Add Baptism'}
                  </h2>

                  <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                     <div className='sm:col-span-4'>
                        <label
                           htmlFor='dateTime'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Date / Time
                        </label>
                        <div className='mt-2'>
                           <select
                              id='dateTime'
                              name='dateTime'
                              {...dateTimeAttr}
                              disabled={isDatesLoading}
                              className='disabled:bg-gray-100 disabled:cursor-wait block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6'
                           >
                              <option value=''>Select</option>
                              {Array.isArray(baptismDates)
                                 ? (
                                      [
                                         pastBaptismDateQuery?.data,
                                         ...baptismDates,
                                      ] as TBaptismDate[]
                                   )
                                      .filter(Boolean)
                                      .map(baptismDate => (
                                         <option
                                            value={baptismDate._id}
                                            key={baptismDate._id}
                                         >
                                            {new Date(
                                               baptismDate.date
                                            ).toLocaleString('en-us', {
                                               day: 'numeric',
                                               month: 'long',
                                               year: 'numeric',
                                               hour: 'numeric',
                                               minute: 'numeric',
                                            })}
                                         </option>
                                      ))
                                 : null}
                              {/* <option>August 3, 2024 at 10am</option>
                           <option>August 11, 2024 at 1pm</option> */}
                           </select>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='border-b border-gray-900/10 pb-12'>
                  <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='first-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           First name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='first-name'
                              id='first-name'
                              {...firstnameAttr}
                              autoComplete='given-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='middle-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Middle name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='middle-name'
                              id='middle-name'
                              {...middleNameAttr}
                              autoComplete='middle-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='last-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Last name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='last-name'
                              id='last-name'
                              {...lastnameAttr}
                              autoComplete='family-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='dateOfBirth'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Date of Birth
                        </label>
                        <div className='mt-2'>
                           <input
                              type='date'
                              name='dateOfBirth'
                              id='dateOfBirth'
                              {...dobAttr}
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>
                     {/* <!-- other elements --> */}
                  </div>
               </div>

               <div className='border-b border-gray-900/10 pb-12'>
                  <h2 className='text-base font-semibold leading-7 text-gray-900'>
                     Parents
                  </h2>

                  <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='mother-first-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Mother's First Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='mother-first-name'
                              id='mother-first-name'
                              {...mFirstnameAttr}
                              autoComplete='mother-firstname-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='mother-middle-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Mother's Middle Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='mother-middle-name'
                              id='mother-middle-name'
                              {...mMiddlenameAttr}
                              autoComplete='mother-middle-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='mother-last-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Mother's Last Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              {...mLastnameAttr}
                              name='mother-last-name'
                              id='mother-last-name'
                              autoComplete='mother-last-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='father-first-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Father's First Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='father-first-name'
                              {...fFirstnameAttr}
                              id='father-first-name'
                              autoComplete='father-first-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='father-middle-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Father's Middle Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='father-middle-name'
                              id='father-middle-name'
                              {...fMiddlenameAttr}
                              autoComplete='father-middle-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='father-last-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Father's Last Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='father-last-name'
                              id='father-last-name'
                              {...fLastnameAttr}
                              autoComplete='father-last-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     {/* <!-- other elements --> */}
                  </div>
               </div>

               {/* <!--godparents--> */}

               <div className='border-b border-gray-900/10 pb-12'>
                  <h2 className='text-base font-semibold leading-7 text-gray-900'>
                     Godparents
                  </h2>

                  <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='godparent1-first-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Godparent 1 First Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='godparent1-first-name'
                              {...g1FirstnameAttr}
                              id='godparent1-first-name'
                              autoComplete='godparent1-first-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='godparent1-middle-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Godparent 1 Middle Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='godparent1-middle-name'
                              {...g1MiddlenameAttr}
                              id='godparent1-middle-name'
                              autoComplete='godparent1-middle-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='godparent1-last-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Godparent 1 Last Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='godparent1-last-name'
                              {...g1LastnameAttr}
                              id='godparent1-last-name'
                              autoComplete='godparent1-last-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='godparent2-first-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Godparent 2 First Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='godparent2-first-name'
                              id='godparent2-first-name'
                              {...g2FirstnameAttr}
                              autoComplete='godparent2-first-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='godparent2-middle-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Godparent 2 Middle Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='godparent2-middle-name'
                              {...g2MiddlenameAttr}
                              id='godparent2-middle-name'
                              autoComplete='godparent2-middle-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='godparent2-last-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Godparent 2 Last Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              {...g2LastnameAttr}
                              name='godparent2-last-name'
                              id='godparent2-last-name'
                              autoComplete='godparent2-last-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     {/* <!-- other elements --> */}
                  </div>
               </div>

               <div className='border-b border-gray-900/10 pb-12'>
                  <h2 className='text-base font-semibold leading-7 text-gray-900'>
                     Witness
                  </h2>

                  <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='witness-first-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Witness First Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='witness-first-name'
                              {...wFnameAttr}
                              id='witness-first-name'
                              autoComplete='witness-first-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='witness-middle-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Witness Middle Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='witness-middle-name'
                              {...wMnameAttr}
                              id='witness-middle-name'
                              autoComplete='witness-middle-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>

                     <div className='sm:col-span-2'>
                        <label
                           htmlFor='witness-last-name'
                           className='block text-sm font-medium leading-6 text-gray-900'
                        >
                           Witness Last Name
                        </label>
                        <div className='mt-2'>
                           <input
                              type='text'
                              name='witness-last-name'
                              {...wLnameAttr}
                              id='witness-last-name'
                              autoComplete='witness-last-name'
                              className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                           />
                        </div>
                     </div>
                  </div>
               </div>

               <div className='border-b border-gray-900/10 pb-12 mt-10'>
                  <div className='mt-10 space-y-10'>
                     <fieldset>
                        <legend className='text-sm font-semibold leading-6 text-gray-900'>
                           Documents
                        </legend>
                        <div className='mt-6 space-y-6'>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='birthCertificate'
                                    name='birthCertificate'
                                    checked={birthCertificate}
                                    onChange={handleCheckChange(
                                       setBirthCertificate
                                    )}
                                    type='checkbox'
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='birthCertificate'
                                    className='font-medium text-gray-900'
                                 >
                                    Birth Certificate of the To-Be-Baptized
                                 </label>
                              </div>
                           </div>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='allParentDocs'
                                    name='allParentDocs'
                                    checked={allParentDocs}
                                    onChange={handleCheckChange(
                                       setAllParentDocs
                                    )}
                                    type='checkbox'
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='allParentDocs'
                                    className='font-medium text-gray-900'
                                 >
                                    All Parent Docs
                                 </label>
                              </div>
                           </div>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='godmotherDocs'
                                    name='godmotherDocs'
                                    type='checkbox'
                                    checked={godmotherDocs}
                                    onChange={handleCheckChange(
                                       setGodmotherDocs
                                    )}
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='godmotherDocs'
                                    className='font-medium text-gray-900'
                                 >
                                    Godmother Docs
                                 </label>
                              </div>
                           </div>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='godfatherDocs'
                                    name='godfatherDocs'
                                    type='checkbox'
                                    checked={godfatherDocs}
                                    onChange={handleCheckChange(
                                       setGodfatherDocs
                                    )}
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='godfatherDocs'
                                    className='font-medium text-gray-900'
                                 >
                                    Godfather Docs
                                 </label>
                              </div>
                           </div>
                        </div>
                     </fieldset>
                  </div>
               </div>

               {birthCertificate ? (
                  <div className='mt-6'>
                     {birthCertificateFile &&
                     'name' in birthCertificateFile &&
                     birthCertificateFile?.name ? (
                        <>
                           <label
                              htmlFor='birthCertificateFile'
                              className='block text-sm font-medium text-gray-700'
                           >
                              Birth Certificate PDF -{' '}
                              {birthCertificateFile.name}
                           </label>
                           <div className='mt-1 flex items-center'>
                              <button
                                 type='button'
                                 onClick={() => setBirthCertificateFile(null)}
                                 className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              >
                                 Change
                              </button>
                           </div>
                        </>
                     ) : (
                        <FileInput
                           name='birthCertificateFile'
                           label='Birth Certificate PDF'
                           dropzoneOptions={birthCertOptions}
                           type='pdf'
                           maxSizeInMB={30}
                        />
                     )}
                  </div>
               ) : null}

               {allParentDocs ? (
                  <div className='mt-6'>
                     {allParentDocsFile &&
                     'name' in allParentDocsFile &&
                     allParentDocsFile?.name ? (
                        <>
                           <label
                              htmlFor='allParentDocsFile'
                              className='block text-sm font-medium text-gray-700'
                           >
                              All Parent Docs PDF - {allParentDocsFile.name}
                           </label>
                           <div className='mt-1 flex items-center'>
                              <button
                                 type='button'
                                 onClick={() => setAllParentDocsFile(null)}
                                 className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              >
                                 Change
                              </button>
                           </div>
                        </>
                     ) : (
                        <FileInput
                           name='allParentDocsFile'
                           label='All Parent Docs PDF'
                           dropzoneOptions={allParentDocsOptions}
                           type='pdf'
                           maxSizeInMB={30}
                        />
                     )}
                  </div>
               ) : null}

               {godmotherDocs ? (
                  <div className='mt-6'>
                     {godmotherDocsFile &&
                     'name' in godmotherDocsFile &&
                     godmotherDocsFile?.name ? (
                        <>
                           <label
                              htmlFor='godmotherDocsFile'
                              className='block text-sm font-medium text-gray-700'
                           >
                              Godmother Docs PDF - {godmotherDocsFile.name}
                           </label>
                           <div className='mt-1 flex items-center'>
                              <button
                                 type='button'
                                 onClick={() => setGodmotherDocsFile(null)}
                                 className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              >
                                 Change
                              </button>
                           </div>
                        </>
                     ) : (
                        <FileInput
                           name='godmotherDocsFile'
                           label='Godmother Docs PDF'
                           dropzoneOptions={godmotherDocsOptions}
                           type='pdf'
                           maxSizeInMB={30}
                        />
                     )}
                  </div>
               ) : null}

               {godfatherDocs ? (
                  <div className='mt-6'>
                     {godfatherDocsFile &&
                     'name' in godfatherDocsFile &&
                     godfatherDocsFile?.name ? (
                        <>
                           <label
                              htmlFor='godfatherDocsFile'
                              className='block text-sm font-medium text-gray-700'
                           >
                              Godfather Docs PDF - {godfatherDocsFile.name}
                           </label>
                           <div className='mt-1 flex items-center'>
                              <button
                                 type='button'
                                 onClick={() => setGodfatherDocsFile(null)}
                                 className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              >
                                 Change
                              </button>
                           </div>
                        </>
                     ) : (
                        <FileInput
                           name='godfatherDocsFile'
                           label='Godfather Docs PDF'
                           dropzoneOptions={godfatherDocsOptions}
                           type='pdf'
                           maxSizeInMB={30}
                        />
                     )}
                  </div>
               ) : null}

               <div className='border-b border-gray-900/10 pb-12 mt-10'>
                  <div className='mt-10 space-y-10'>
                     <fieldset>
                        <legend className='text-sm font-semibold leading-6 text-gray-900'>
                           Baptism Class Complete
                        </legend>
                        <div className='mt-6 space-y-6'>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='baptismClassParents'
                                    name='baptismClassParents'
                                    checked={baptismClassParents}
                                    onChange={handleCheckChange(
                                       setBaptismClassParents
                                    )}
                                    type='checkbox'
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='baptismClassParents'
                                    className='font-medium text-gray-900'
                                 >
                                    Baptism Class Parents
                                 </label>
                              </div>
                           </div>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='baptismClassGodmother'
                                    name='baptismClassGodmother'
                                    checked={baptismClassGodmother}
                                    onChange={handleCheckChange(
                                       setBaptismClassGodmother
                                    )}
                                    type='checkbox'
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='baptismClassGodmother'
                                    className='font-medium text-gray-900'
                                 >
                                    Baptism Class Godmother
                                 </label>
                              </div>
                           </div>
                           <div className='relative flex gap-x-3'>
                              <div className='flex h-6 items-center'>
                                 <input
                                    id='baptismClassGodfather'
                                    name='baptismClassGodfather'
                                    type='checkbox'
                                    checked={baptismClassGodfather}
                                    onChange={handleCheckChange(
                                       setBaptismClassGodfather
                                    )}
                                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                 />
                              </div>
                              <div className='text-sm leading-6'>
                                 <label
                                    htmlFor='baptismClassGodfather'
                                    className='font-medium text-gray-900'
                                 >
                                    Baptism Class Godfather
                                 </label>
                              </div>
                           </div>
                        </div>
                     </fieldset>
                  </div>
               </div>

               <div className='border-b border-gray-900/10 pb-12'>
                  <div className='mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                     <div className='sm:col-span-6'>
                        <label
                           htmlFor='about'
                           className='block text-sm font-medium text-gray-700'
                        >
                           Notes
                        </label>
                        <div className='mt-1'>
                           <textarea
                              id='about'
                              name='about'
                              {...notesAttr}
                              rows={3}
                              className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md'
                           ></textarea>
                        </div>
                     </div>
                  </div>
               </div>

               <div className='mt-6 flex items-center justify-between gap-x-6'>
                  <div>
                     {baptismDoc?._id ? (
                        <button
                           type='button'
                           disabled={deleteMutation.isLoading}
                           onClick={() => setOpenDeleteModal(true)}
                           className='disabled:bg-gray-400 disabled:cursor-wait disabled:hover:bg-gray-400 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        >
                           {deleteMutation.isLoading
                              ? 'Deleting...'
                              : 'Delete Baptism'}
                        </button>
                     ) : null}
                  </div>
                  <div className='gap-x-6 items-center flex'>
                     <button
                        type='button'
                        onClick={() => navigate(-1)}
                        className='text-sm font-semibold leading-6 text-gray-900'
                     >
                        Cancel
                     </button>
                     <button
                        type='submit'
                        disabled={isLoading}
                        className='disabled:bg-gray-400 disabled:cursor-wait disabled:hover:bg-gray-400 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                     >
                        Save
                     </button>
                  </div>
               </div>
            </div>
         </form>

         <ConfirmBox
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            callback={() => deleteMutation.mutate()}
            title='Delete Baptism'
            confirmMsg={`Baptism of ${baptismDoc?.firstname} ${baptismDoc?.lastname} will be deleted parmanently. Do you want to proceed?`}
            handleConfirmCancel={() => setOpenDeleteModal(false)}
         />
      </>
   );
}
