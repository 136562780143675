export function objectToFormdata(
   obj: Record<string, any>,
   parentKey = ''
): FormData {
   const formData = new FormData();

   for (let key in obj) {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
         // Handle nested objects recursively
         const nestedFormData = objectToFormdata(obj[key], newKey);
         nestedFormData.forEach((value, key) => {
            formData.append(key, value);
         });
      } else {
         formData.append(newKey, obj[key]);
      }
   }

   return formData;
}

export function toCamelCase(str: string) {
   return str
      .replace(/[-_ ]+([a-z])/g, (_, letter) => letter.toUpperCase())
      .replace(/^([A-Z])/, (_, letter) => letter.toLowerCase());
}

export function trimStringFields(obj: Record<string, any>) {
   const trimmedData = Object.keys(obj).reduce(
      (acc: Record<string, any>, key) => {
         acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
         return acc;
      },
      {}
   );
   return trimmedData;
}

export function appendTransformationsToCloudinaryUrl(
   url: string,
   transformations: string[]
) {
   // Find the position to insert transformations
   const uploadIndex = url.indexOf('/upload/') + '/upload/'.length;

   // Build the transformation string
   const transformationString = transformations.join(',');

   // Insert the transformations before the version or filename
   const newUrl =
      url.slice(0, uploadIndex) +
      transformationString +
      '/' +
      url.slice(uploadIndex);

   return newUrl;
}
