import { DocumentPlusIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef } from 'react';
import { DropzoneState } from 'react-dropzone';

type TFileTypes = 'image' | 'pdf' | 'xls' | 'xlsx';

type TProps = {
   label: string;
   name: string;
   dropzoneOptions: DropzoneState;
   maxSizeInMB?: number;
   type: TFileTypes | TFileTypes[];
};

export default function FileInput({
   label,
   name,
   dropzoneOptions,
   maxSizeInMB = 10,
   type,
}: TProps) {
   const { getRootProps, getInputProps, isDragActive } = dropzoneOptions;
   const buttonRef = useRef<null | HTMLButtonElement>(null);

   useEffect(() => {
      if (!buttonRef?.current) return;
      isDragActive ? buttonRef.current.focus() : buttonRef.current.blur();
   }, [isDragActive]);

   return (
      <>
         <label
            htmlFor={name}
            className='block text-sm font-medium text-gray-700'
         >
            {label}
         </label>
         <button
            type='button'
            className='mt-1 w-full flex justify-center px-6 pt-5 pb-6 border border-gray-300 border-dashed rounded-md'
            {...getRootProps()}
            ref={buttonRef}
         >
            <div className='space-y-1 text-center'>
               {type === 'image' ? (
                  <svg
                     className='mx-auto h-12 w-12 text-gray-400'
                     stroke='currentColor'
                     fill='none'
                     viewBox='0 0 48 48'
                     aria-hidden='true'
                  >
                     <path
                        d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                     />
                  </svg>
               ) : type === 'pdf' ? (
                  <DocumentPlusIcon className='h-12 w-12 mx-auto text-gray-400' />
               ) : type === 'xls' || type === 'xlsx' ? (
                  <TableCellsIcon className='h-12 w-12 mx-auto text-gray-400' />
               ) : null}
               <div className='flex text-sm text-gray-600'>
                  <label
                     htmlFor={name}
                     className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                  >
                     <span>Upload a file</span>
                     <input
                        name={name}
                        {...getInputProps()}
                        className='sr-only'
                     />
                  </label>
                  <p className='pl-1'>or drag and drop</p>
               </div>
               <p className='text-xs text-gray-500'>
                  {type === 'image'
                     ? 'PNG, JPG or GIF'
                     : type === 'pdf'
                     ? 'PDF'
                     : type === 'xls'
                     ? 'XLSX or XLS'
                     : type === 'xlsx'
                     ? 'XLSX'
                     : null}{' '}
                  up to {maxSizeInMB}MB
               </p>
            </div>
         </button>
      </>
   );
}
